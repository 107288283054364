import { RoleService } from "@/services";
// import Hashids from "hashids";
// let hashids = new Hashids("", 6);
import includes from "lodash/includes";
import cloneDeep from "lodash/cloneDeep";

const slugAgents = ["staff", "new-agent"];

const state = {
  access: {
    apps: [],
    groups: [],
    module: [],
  },
  isAgent: null,
  roleAgent: null,
  roleName: "",
  canInviteMember: false,
  cantAddConversationTag: false,
  userRole: null,
  isTokenExpired: false,
};

const getters = {
  getAccess(state) {
    return cloneDeep(state.access);
  },
  isAgent(state) {
    return state.isAgent;
  },
  roleAgent(state) {
    return state.roleAgent;
  },
  roleName(state) {
    return state.roleName;
  },
  canInviteMember(state) {
    return state.canInviteMember;
  },
  cantAddConversationTag(state) {
    return state.cantAddConversationTag;
  },
  userRole(state) {
    return state.userRole;
  },
  isTokenExpired(state) {
    return state.isTokenExpired;
  },
};
const mutations = {
  SET_ACCESS(state, payload) {
    state.access = payload;
  },
  SET_ROLE: (state, payload) => {
    if (
      slugAgents.find((r) => r === payload.slug) ||
      payload.slug.includes("agent")
    ) {
      state.isAgent = true;
    } else {
      state.isAgent = false;
    }
    state.userRole = payload;
  },
  IS_ROLE_AGENT: (state, payload) => {
    if (
      slugAgents.find((r) => r === payload.slug) ||
      payload.slug.includes("agent")
    ) {
      state.roleAgent = true;
    } else {
      state.roleAgent = false;
    }
  },
  SET_ROLE_NAME: (state, payload) => {
    state.roleName = payload;
  },
  CAN_INVITE_MEMBER: (state, payload) => {
    // if role is agent / admin IT / supervisor agent
    // V2
    // if (
    //   payload == "staff" ||
    //   payload == "admin-it" ||
    //   payload == "supervisor-agent"
    // ) {
    //   state.canInviteMember = false;
    // } else {
    //   state.canInviteMember = true;
    // }
    // END V2
    // V3
    const x = includes(payload, "can-invite-member");
    state.canInviteMember = x;
    // END V3
  },
  CANT_ADD_CONVERSATION_TAG: (state, payload) => {
    // if role is agent / admin IT / supervisor agent
    // V2
    // if (
    //   payload == "staff" ||
    //   payload == "admin-it" ||
    //   payload == "supervisor-agent"
    // ) {
    //   state.canInviteMember = false;
    // } else {
    //   state.canInviteMember = true;
    // }
    // END V2
    // V3
    const x = includes(payload, "cant-add-conversation-tag");
    state.cantAddConversationTag = x;
    // END V3
  },
  SET_IS_LOADING: (state, payload) => {
    state.isLoading = payload;
  },
  SET_IS_EXPIRED: (state, payload) => {
    state.isTokenExpired = payload;
  },
};
const actions = {
  setAccessibility(context, payload) {
    context.commit("SET_ACCESS", {
      ...payload,
      module: [
        ...payload.module,
        {
          id: 11,
          name: "Campaign",
          slug: "campaign",
          order: 11,
          type: "direct",
          url: "campaign",
          route: "campaign",
          icon: "ri-share-line",
          v3_menus: [
            {
              id: 99,
              name: "Transaction",
              icon: "ri-file-list-3-fill",
              slug: "transactions",
              plans: [1, 2, 3],
              url: "manage",
              description: null,
              submenus: [
                {
                  id: 87,
                  name: "Collected Response",
                  description: null,
                  icon: "ri-pie-chart-box-fill",
                  slug: "collected-response-list",
                  plans: [1, 2, 3],
                  url: "collected-response-list",
                  background: null,
                  sub_submenus: [],
                },
              ],
            },
          ],
        },
        {
          id: 12,
          name: "KnowledgeBase",
          slug: "knowledge-base",
          order: 12,
          type: "direct",
          url: "knowledge-base",
          route: "knowledge-base",
          icon: "ri-share-line",
          v3_menus: [
            {
              id: 99,
              name: "Transaction",
              icon: "ri-file-list-3-fill",
              slug: "transactions",
              plans: [1, 2, 3],
              url: "manage",
              description: null,
              submenus: [
                {
                  id: 87,
                  name: "Collected Response",
                  description: null,
                  icon: "ri-pie-chart-box-fill",
                  slug: "collected-response-list",
                  plans: [1, 2, 3],
                  url: "collected-response-list",
                  background: null,
                  sub_submenus: [],
                },
              ],
            },
          ],
        },
      ],
    });
  },
  async getRole(context) {
    context.commit("SET_IS_LOADING", true);
    let response = await this.$axios.get(
      `api/${context.rootGetters.appId.hashed}/accessibility/get-role`
    );
    context.commit("SET_ROLE", response.data);
    context.commit("CAN_INVITE_MEMBER", response.data.slug);
    context.commit("SET_ROLE_NAME", response.data.name);
  },
  async getRoleV3(context) {
    context.commit("SET_IS_LOADING", true);
    try {
      const { data: response } = await RoleService.getAccessibilities();
      context.commit("SET_ROLE", response);
      context.commit("CAN_INVITE_MEMBER", response.privilege);
      context.commit("CANT_ADD_CONVERSATION_TAG", response.privilege);
      context.commit("SET_ROLE_NAME", response.name);
      context.commit("SET_IS_EXPIRED", false);
    } catch (err) {
      context.commit("SET_IS_EXPIRED", true);
    }
  },
  async setRole(context) {
    context.commit("SET_IS_LOADING", true);
    try {
      const { data: response } = await RoleService.getAccessibilities();
      context.commit("IS_ROLE_AGENT", response);
    } catch (err) {
      context.commit("SET_IS_EXPIRED", true);
    }
  },
};
export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
