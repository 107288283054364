import { httpClientOmni } from "@/libraries";
import { useRoute } from "vue-router";

export default function useApi() {
  const route = useRoute();
  const { integrationId } = route.params;
  const getStyle = async () => {
    const response = await httpClientOmni.get(`/v4/webchat/${integrationId}`);
    return response.data;
  };

  const getBroadcastAnalytic = async (id) => {
    const response = await httpClientOmni.get(
      `/v3/broadcast/email/${id}/analytics`
    );

    return response.data;
  };

  const getBroadcastDetail = async (id) => {
    const response = await httpClientOmni.get(`/v3/broadcast/email/${id}`);

    return response.data;
  };

  const sendBroadcastTest = async (id, data) => {
    const response = await httpClientOmni.post(
      `/v3/broadcast/email/send-test/${id}`,
      data
    );

    return response.data;
  };

  const sendBroadcast = async (id) => {
    const response = await httpClientOmni.post(
      `/v3/broadcast/email/send/${id}`
    );

    return response.data;
  };

  const updateBroadcast = async (id, data) => {
    const response = await httpClientOmni.put(
      `/v3/broadcast/email/update/${id}`,
      data
    );

    return response.data;
  };

  const getBroadcastEmail = async (params) => {
    const response = await httpClientOmni.get("/v3/broadcast/email", {
      params,
    });

    return response.data;
  };

  const createBroadcastEmail = async (data) => {
    const response = await httpClientOmni.post(
      "/v3/broadcast/email/create",
      data
    );

    return response.data;
  };

  const getTemplate = async (params) => {
    const response = await httpClientOmni.get("/v3/broadcast/email/template", {
      params,
    });

    return response.data;
  };

  const getTemplateDetail = async (id) => {
    const response = await httpClientOmni.get(
      `/v3/broadcast/email/template/${id}`
    );

    return response.data;
  };

  const updateTemplate = async (id, data) => {
    const response = await httpClientOmni.put(
      `/v3/broadcast/email/template/${id}`,
      data
    );

    return response.data;
  };

  const createTemplate = async (data) => {
    const response = await httpClientOmni.post(
      "/v3/broadcast/email/template",
      data
    );

    return response.data;
  };

  const deleteTemplate = async (id) => {
    const response = await httpClientOmni.delete(
      `/v3/broadcast/email/template/${id}`
    );

    return response.data;
  };

  const contactActivity = async (id) => {
    const response = await httpClientOmni.get(
      `/v3/broadcast/contact/activities/${id}`
    );

    return response.data;
  };

  const emailCampaign = async (id) => {
    const response = await httpClientOmni.get(
      `/v3/broadcast/contact/email-campaign/${id}`
    );

    return response.data;
  };

  const viewContact = async (id) => {
    const response = await httpClientOmni.get(`/v3/broadcast/contact/${id}`);

    return response.data;
  };

  const createContact = async (data) => {
    const response = await httpClientOmni.post(
      "/v3/broadcast/contact/save",
      data
    );
    return response.data;
  };

  const updateContact = async (data, id) => {
    const response = await httpClientOmni.put(
      `/v3/broadcast/contact/${id}`,
      data
    );

    return response.data;
  };

  const bulkAction = async ({ data, type, property, additionalProperty }) => {
    const response = await httpClientOmni.post("/v3/broadcast/contact/bulk", {
      type,
      [property]: data,
      ...additionalProperty,
    });
    return response.data;
  };

  const getGroup = async (params = {}) => {
    const response = await httpClientOmni.get(`/v3/broadcast/group`, {
      params,
    });
    return response.data;
  };

  const createGroup = async (data) => {
    const response = await httpClientOmni.post(`/v3/broadcast/group`, data);
    return response.data;
  };

  const updateGroup = async (data, id) => {
    const response = await httpClientOmni.put(
      `/v3/broadcast/group/${id}`,
      data
    );
    return response.data;
  };

  const previewImport = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    const response = await httpClientOmni.post(
      `/v3/broadcast/contact/import`,
      formData
    );
    return response.data;
  };

  return {
    getStyle,
    getGroup,
    createGroup,
    updateGroup,
    previewImport,
    createContact,
    contactActivity,
    emailCampaign,
    viewContact,
    updateContact,
    bulkAction,
    updateTemplate,
    createTemplate,
    getTemplate,
    getTemplateDetail,
    deleteTemplate,
    createBroadcastEmail,
    getBroadcastEmail,
    sendBroadcastTest,
    sendBroadcast,
    updateBroadcast,
    getBroadcastDetail,
    getBroadcastAnalytic,
  };
}
